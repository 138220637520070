// src/components/Button/Button.js
import React from 'react';
import './Button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ label, onClick, type = "primary",  icon, iconSize,iconColor }) => {
  return (
    <button className={`button ${type}`} onClick={onClick}>
      {icon && <FontAwesomeIcon icon={icon} size="lg" style={{color:iconColor,size:iconSize}}  />} {/* Conditionally render the icon */}
      {label}   
    </button>
  );
};

export default Button;
