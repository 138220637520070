import axios from "axios";
import moment from "moment";
//apply base url for axios
import { BASE_API_URL } from "../../constants/api";

const axiosApi = axios.create({
  baseURL: 'https://x8ki-letl-twmt.n7.xano.io/api:aqhe2OOM/',//BASE_API_URL,
});

//pass new generated access token here
export const setHeaders = (AUTH_TOKEN) => {
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
};

export async function get(url, data = {}, config = {}) {
  const res = await axiosApi.get(url, { ...data }, { ...config });
  return res.data;
}

export async function post(url, data, config = {}) {
  const res = await axiosApi.post(url, { ...data }, { ...config });
  console.log(res);
  return res.data;
}

export async function put(url, data, config = {}) {
  const res = await axiosApi.put(url, { ...data }, { ...config });
  return res.data;
}

export async function patch(url, data, config = {}) {
  const res = axiosApi.patch(url, { ...data }, { ...config });
  return res.data;
}

export async function del(url, config = {}) {
  const res = await axiosApi.delete(url, { ...config });
  return res.data;
}
