import React, { useEffect, useState } from "react";
import "./custom-table.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faRectangleXmark,
  faCirclePlay,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import { columnToKeyMap } from "../../constants/config";
import Associate from "../associate/associate";
import { patch, post, get } from "../../singelton/axios";
import { Bounce, toast } from "react-toastify";

const CustomTable = ({ data, selectedTab, fetchAllData, tablesData }) => {
  const [associate, setAssociate] = useState("");
  const [users, setUsers] = useState();
  useEffect(() => {
    get("/user").then((res) => {
      let usr = res.filter((x) => x.user_types_id === 2);
      setUsers(usr);
    });
  }, []);
  let isInterventions = selectedTab === "Interventions";
  let isClient = selectedTab === "Client";
  const completeAlert = (record) => {
    if (isClient) {
      // complete the alerts
      let bodyAlerts = {
        ...record,
        alerts_status_types_id: 3, // Completed
      };
      patch(`/alerts/${record.id}`, bodyAlerts).then((res) => {
        setTimeout(() => {
          fetchAllData("alerts");
        }, 1000);
        toast.success("Alert Completed Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
    } else {
      if (!associate) return;
      //add interventions with assigned status
      let userdata = localStorage.getItem("userData");
      userdata = JSON.parse(userdata);
      let interventionBody = {
        intervention_types_id: 1, // Start a job at ( to be discussed with Scott )
        Intervetion_Number: 1,
        initiated_by: userdata.id,
        performed_by: associate,
        alerts_id: record.id,
        intervention_status_types_id: 2, // Assigned
        locations_id: record?._jobs?.locations_id, // get location of job via jobId in alert
      };
      post("/interventions", interventionBody)
        .then((res) => {
          toast.success("Intervention Created Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          // complete the alerts
          let bodyAlerts = {
            ...record,
            alerts_status_types_id: 3, // Completed
          };
          patch(`/alerts/${record.id}`, bodyAlerts).then((res) => {
            setTimeout(() => {
              fetchAllData("alerts");
            }, 1000);
            toast.success("Alert Completed Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          });
        })
        .catch((err) => console.log(err));
      console.log("create intervention:", record, interventionBody);
    }
  };

  const completeIntervention = (record) => {
    let interventionBody = {
      ...record,
      intervention_status_types_id: 3, // Re-assigned
    };

    patch(`/interventions/${record.id}`, interventionBody)
      .then((res) => {
        // complete the alerts
        toast.success("Intervention Re-Assigned!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
        let alertRow = tablesData?.totalAlerts.find(
          (x) => x.id === record.alerts_id
        );
        let bodyAlerts = {
          ...alertRow,
          alerts_status_types_id: 2, // Needs-Reassignement
        };
        patch(`/alerts/${record.alerts_id}`, bodyAlerts).then((res) => {
          toast.success("Alert Re-Assignment created Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          fetchAllData("interventions");
        }, 1000);
      });
  };

  const onPlayPause = (row, pause) => {
    // pause the job
    let userdata = localStorage.getItem("userData");
    userdata = JSON.parse(userdata);
    let isSuperVisor = userdata?.user_types_id === 1;
    let bodyJob = {
      ...row,
      job_status_types_id: pause ? 5 : 10, // Paused-Need Rationale
      statusChangeByUser: userdata.id,
    };
    patch(`/jobs/${row.id}`, bodyJob)
      .then((res) => {
        setTimeout(() => {
          fetchAllData("jobs");
        }, 1000);
        if (!pause) {
          //create alert-dapster on PLAY
          let userdata = localStorage.getItem("userData");
          userdata = JSON.parse(userdata);
          let bodyAlerts = {
            alert_types_id: 14, // Restart Job
            Source_Type: isSuperVisor ? "Customer" : "Dapster",
            user_id: userdata?.id,
            jobs_id: row.id,
            alerts_status_types_id: 4, // Restarting
            dapster_id: 1,
          };
          post("/alerts", bodyAlerts)
            .then((res) => {
              console.log("alerts dapster generated successfully");
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const changeAlertStatus = (row, status, alertTeleOp) => {
    let alertBody = {
      ...row,
      alerts_status_types_id: status,
    };

    patch(`/alerts/${row.id}`, alertBody)
      .then((res) => {
        if (alertTeleOp) {
          const bodyAlert = {
            ...row,
            alerts_status_types_id: 1,
            Source_Type: "TeleOperator",
          };
          post("/alerts", bodyAlert)
            .then((res) => {
              console.log("alerts dapster generated successfully");
            })
            .catch((err) => console.log(err));
        }
        // changes updated on dapster alerts
        setTimeout(() => {
          fetchAllData("alerts");
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const enableSendAlert = (row) => {
    let alertBody = {
      ...row,
      sendAlertEnabled: true,
    };

    patch(`/alerts/${row.id}`, alertBody)
      .then((res) => {
        setTimeout(() => {
          fetchAllData("alerts");
        }, 1000);
      })
      .catch((err) => console.log(err));
  };
  const changeJobStatus = (row, status) => {
    //cancel job
    let userdata = localStorage.getItem("userData");
    userdata = JSON.parse(userdata);
    let isSuperVisor = userdata?.user_types_id === 1;

    let bodyJob = {
      ...row,
      job_status_types_id: status, // closed-Need Rationale
      statusChangeByUser: userdata.id,
    };
    patch(`/jobs/${row.id}`, bodyJob)
      .then((res) => {
        setTimeout(() => {
          fetchAllData("jobs");
        }, 1000);
        const bodyAlert = {
          ...row,
          alerts_status_types_id: 1, // New
          alert_types_id: isSuperVisor ? 15 : 10, // Close Job / Break down dock door #
          Source_Type: isSuperVisor ? "Customer" : "TeleOperator",
          user_id: userdata?.id,
          jobs_id: row.id,
          dapster_id: 1,
          sendAlertEnabled: false,
        };
        post("/alerts", bodyAlert)
          .then((res) => {
            console.log("alerts customer/teleOp generated successfully");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const getColumnType = (value, index, row) => {
    console.log("value", row);

    let columnName = data?.columns?.length > 0 ? data?.columns[index] : "";
    let elem = <div></div>;
    switch (columnName) {
      case "Dock":
        elem = (
          <td>
            {selectedTab === "Interventions" || selectedTab === "Jobs"
              ? value?._location_types.Location_types +
                " : " +
                value?.Location_Number
              : value?._locations?._location_types.Location_types +
                " : " +
                value?._locations?.Location_Number}
          </td>
        );
        break;

      case "Dapster":
        elem = <td>{value.Dapster_Name}</td>;
        break;

      case "Note":
        elem = <td>{value?.Job_Type}</td>;
        break;
      case "Link":
        elem = (
          <td>
            <a href={value}>View</a>
          </td>
        );
        break;
      case "Action":
        elem = (
          <td className="table-job-actions-wrap">
            {/* job status: starting => Paused-Need Rationale => Re-Starting */}
            <button
              disabled={[1, 10].includes(row.job_status_types_id)}
              className={`table-action-btn play ${
                [1, 10].includes(row.job_status_types_id) ? "disabled" : ""
              }`}
              onClick={() => onPlayPause(row, false)}
            >
              <FontAwesomeIcon icon={faCirclePlay} />
            </button>
            <button
              disabled={[5].includes(row.job_status_types_id)}
              className={`table-action-btn pause ${
                [5].includes(row.job_status_types_id) ? "disabled" : ""
              }`}
              onClick={() => onPlayPause(row, true)}
            >
              <FontAwesomeIcon icon={faCirclePause} />
            </button>
            <button
              className={`table-action-btn cancel `}
              onClick={() => {
                changeJobStatus(row, 8); // close-need-rationals
              }}
            >
              <FontAwesomeIcon icon={faRectangleXmark} />
            </button>
          </td>
        );
        break;

      case "Issue":
        elem = (
          <td>{isInterventions ? value.Intervention_Types : value.Job_Type}</td>
        );
        break;
      case "Worker":
        elem = <td>{value?.NAME}</td>;
        break;
      case "Elapsed Time":
        elem = <td>{value}</td>;
        break;
      case "Status":
        elem = (
          <td>
            {isInterventions
              ? value?.Intervention_Status_Types
              : `${value?.Job_Status_Types} ${
                  row?._user?.NAME ? "by " + row?._user?.NAME : ""
                }`}
          </td>
        );
        break;

      case "Issuer Name":
        elem = <td>{value?.NAME}</td>;
        break;

      case "Issue Detail":
        elem = (
          <td>
            {row?._alerts_status_types?.Alert_Status_Type} :{" "}
            {value?.Alert_Types}
          </td>
        );
        break;

      case "Completed?":
        elem = (
          <td>
            <button
              className="table-action-btn"
              onClick={() => completeAlert(row)}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          </td>
        );
        break;

      case "Resolved?":
        const status = row.Source_Type === "Dapster" && row.sendAlertEnabled;
        elem = (
          <td className="table-job-actions-wrap">
            <button
              className={`table-action-btn play `}
              onClick={() => changeAlertStatus(row, 3, false)}
              disabled={status}
              style={{ cursor: status ? "not-allowed" : "pointer" }}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ opacity: status ? 0.5 : 1 }}
              />
            </button>

            <button
              className={`table-action-btn cancel `}
              onClick={() => {
                enableSendAlert(row);
              }}
            >
              <FontAwesomeIcon icon={faRectangleXmark} />
            </button>
          </td>
        );
        break;
      case "Send Alert":
        const alertStatus =
          row.Source_Type === "Dapster" && !row.sendAlertEnabled;
        elem = (
          <td>
            <button
              className="table-action-btn"
              disabled={alertStatus}
              style={{ cursor: alertStatus ? "not-allowed" : "pointer" }}
              onClick={() => changeAlertStatus(row, 3, true)}
            >
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ opacity: alertStatus ? 0.5 : 1 }}
              />
            </button>
          </td>
        );
        break;
      case "Associate":
        elem = (
          <Associate
            setAssociate={setAssociate}
            associate={associate}
            users={users}
          />
        );
        break;
      case "Assigned":
        elem = (
          <td>
            <button
              className="table-action-btn"
              onClick={() => completeAlert(row)}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          </td>
        );
        break;

      case "Re-Assign?":
        elem = (
          <td>
            <button
              className="table-action-btn"
              onClick={() => completeIntervention(row)}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          </td>
        );
        break;

      default:
        break;
    }
    return elem;
  };

  return (
    <div className="alerts">
      <div className="alerts__container">
        <table className="alerts__table">
          <thead className="alerts__header">
            <tr>
              {data?.columns?.length > 0 &&
                data?.columns?.map((x) => <th>{x}</th>)}
            </tr>
          </thead>
          <tbody className="alerts__body">
            {data?.data?.map((alert, index) => (
              <tr key={alert.id}>
                {data?.columns?.map((x, i) => {
                  let currKey = columnToKeyMap[selectedTab][x]; //Object.keys(alert)[i];
                  console.log(currKey);
                  console.log("alert", alert);
                  return getColumnType(
                    alert.hasOwnProperty(currKey) ? alert[currKey] : null,
                    i,
                    alert
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomTable;
