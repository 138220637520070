import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { subTabsForUsers } from "../../constants/config";
import { getUserType } from "../../utils/helper";
import { get } from "../../singelton/axios";
import {
  faCirclePause,
  faCircleStop,
  faHourglassHalf
} from "@fortawesome/free-solid-svg-icons";
import './worker.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WorkerPanel = () => {

  const [screen, setScreen] = useState(null);
  const [userData, setUserData] = useState(null);
  // Todo:
  // Take location for userdata from localstorage
  // take jobs list
  // show active-component if job with starting status exist on this location
  // show pause-component if job with paused status exist on this location
  // try to use switch based on job status to render respective component 


  useEffect(() => {
    const fetchJobs = async () => {
      let userdata = localStorage.getItem("userData");
      userdata = JSON.parse(userdata);
      console.log("userDta", userdata);
      setUserData(userdata);
      const jobs = await get("/jobs");
      const cur_active_job = jobs.filter(job => job.locations_id === userdata.locations_id && job.job_status_types_id === 1);// starting
      console.log("current active jobs:", cur_active_job);
      if (cur_active_job.length) {
        setScreen(1);
        console.log("hi1");
        return;
      }
      const cur_paused_job = jobs.filter(job => job.locations_id === userdata.locations_id && job.job_status_types_id === 7);// paused
      console.log("current paused jobs:", cur_paused_job);
      console.log("hi");
      if (cur_paused_job.length) {
        const interventions = await getInterventions();
        console.log("interventions:", interventions);
        const filteredInterventions = interventions.filter(intervention => intervention.locations_id === userdata.locations_id && (intervention.status_types_id === 2 || intervention.status_types_id === 4));
        console.log("filtered interventions:", filteredInterventions);
        if (filteredInterventions)
          setScreen(2);
      }
    }
    fetchJobs();
  }, [])

  const getInterventions = async () => {
    const intervention = await get("/interventions");
    return intervention;
  }










  return (
    <>

      {screen == 1 ? (
        <div > <h2>Dapster is hard at work.</h2>
          <p style={{ color: '#A90505', fontWeight: 'bold', fontSize: '30px' }}>Please do not enter the container</p>
          <p>If you need to enter or pause Dapster, please submit your request.</p>
          <p>A Supervisor will approve the request.Do not enter the container until this screen says it is safe to do so</p>
          <div className="align-buttons">
            <Button onClick={null} label="Stop (it's an emergency)" type="primary" icon={faCircleStop} />
            <Button onClick={null} label="Pause/Cancel" type="secondary" icon={faCirclePause} />
            <Button onClick={null} label="Why do you want to Cancel" type="secondary" />
          </div></div>
      ) : null}
      {screen == 2 ? ((<div>
        <h2 style={{ paddingTop: '50px' }}>Dapster is Pausing, and your request has been sent to a Supervisor</h2>
        <FontAwesomeIcon icon={faHourglassHalf} size="5x" style={{ color: "#09375F" }} />
        <p style={{ color: '#A90505', fontWeight: 'bold', fontSize: '30px' }}>Please do not enter the container</p>
        <p style={{ fontWeight: '500' }}>We will let You know when it is safe to enter the container</p>
      </div>)
      ): null}



    </>

  );

};

export default WorkerPanel;
